import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

const SEO = ({ title, description, alias }) => {
  const { seo, currentLangCode } = useSelector((state) => state.system);
  const { t } = useTranslation("main");

  let item;

  if (!title && !description) {
    item = seo.find((s) => s.alias === alias);
  }

  const metaTitle = title
    ? `${title} - ${t("metaTitle")}`
    : get(item, `meta_title_${currentLangCode}`);
  const metaDescription = description
    ? ` ${t("metaDesc1")} ${title}  ${t("metaDesc2")}`
    : get(item, `meta_description_${currentLangCode}`);
  console.log("Meta Title:", metaTitle);
  console.log("Meta Description:", metaDescription);

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
};

export default SEO;
