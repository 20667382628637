import React, { useEffect, useState } from "react";
import get from "lodash/get";
import qs from "qs";
import { LoadMoreVisible, NoData, Seo, Spinner } from "../../components";
import EntityContainer from "../../modules/entity/containers";
import outsideClick from "../../hooks/OutsideClick";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ProductCard } from "../../components/Cards";

const Single = ({ match, location, history }) => {
  const { slug } = match.params;
  const [page, setPage] = useState(1);
  const categories = useSelector((state) => state.system.categories);
  const { t } = useTranslation("main");
  const currentLangCode = useSelector((state) => state.system.currentLangCode);
  const [filterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const { ref, isVisible, setIsVisible } = outsideClick();

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const filterData = params.filterData
    ? JSON.parse(atob(params.filterData))
    : {};

  const selectedCategories = get(filterData, "categories", []);
  const selectedType = get(filterData, "type", "all");

  // Clear all filters
  const clearFilter = () => {
    setPage(1);
    const newVal = { ...filterData, categories: [], type: "all" };
    setParams(newVal);
  };

  // Remove a specific filter
  const removeHandler = (id) => {
    setPage(1);
    const updatedCategories = selectedCategories.filter((sc) => sc !== id);
    const newVal = { ...filterData, categories: updatedCategories };
    setParams(newVal);
  };

  // Update the URL params and reset the page
  const setParams = (obj) => {
    setPage(1);
    history.push({
      search: qs.stringify(
        { filterData: btoa(JSON.stringify(obj)) },
        { encode: false }
      ),
    });
  };

  // Set the product type filter
  const setProductType = (val) => {
    const newVal = { ...filterData, type: val };
    setParams(newVal);
  };

  // Manage body overflow on mobile when filters are open
  useEffect(() => {
    document.body.style.overflow = filterVisible ? "hidden" : "auto";
  }, [filterVisible]);

  // Reset page and data when slug changes
  useEffect(() => {
    setPage(1); // Reset page to 1 when slug changes
    setLoading(true); // Start loading when slug changes
  }, [slug]);

  return (
    <div className={"site-wrapper"}>
      <Seo alias={"catalog"} />
      <div className="catalog-section">
        <div className="container">
          <div className="m-box">
            <h1 className="m-title">
              {t("Категории")} -{" "}
              {get(
                categories.find((c) => c.slug === slug),
                `name_${currentLangCode}`
              )}
            </h1>
          </div>
          <div className="catalog-section-wrapSlug">
            <div className="catalog-section-wrap__contentSlug">
              <div className="catalog-wrapper">
                {/* Entity Container for fetching products */}
                <EntityContainer.All
                  entity="products"
                  name={`all`}
                  url={`/categories/${slug}`}
                  primaryKey="slug"
                  params={{
                    page,
                    limit: 20,
                    filter: {
                      is_sale: selectedType === "is_sale" ? 1 : undefined,
                      is_new: selectedType === "is_new" ? 1 : undefined,
                    },
                  }}
                  dataKey={"products"}
                  onFetchComplete={() => setLoading(false)} // Fetch qachon tugasa loadingni to'xtatadi
                  appendData={page > 1} // Faqat sahifa oshganda yangi ma'lumot qo'shish
                >
                  {({ items, meta, isFetched, isLoading }) => {
                    if (isLoading) {
                      return <Spinner position={"center"} md />; // Yuklanayotganda Spinner ko'rsatiladi
                    }

                    return (
                      <>
                        {items.length > 0 ? (
                          <div className="catalog-wrapper__innerSlug">
                            {items.map((item, index) => (
                              <ProductCard
                                key={index}
                                {...{ item, currentLangCode, t }}
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="">
                            {isFetched ? (
                              <NoData />
                            ) : (
                              <Spinner position={"center"} md />
                            )}
                          </div>
                        )}
                        {get(meta, "last_page") > get(meta, "current_page") && (
                          <LoadMoreVisible
                            setPage={() => setPage((prev) => prev + 1)}
                          />
                        )}
                      </>
                    );
                  }}
                </EntityContainer.All>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Single;
