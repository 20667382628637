import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import get from "lodash/get";
import { helpers } from "../../../services";

const NewsSection = ({
  items,
  title,
  hasAllBtn = false,
  currentLangCode,
  t,
}) => {
  useEffect(() => {
    if (items.length > 0) {
      setTimeout(() => {
        const imageCardHeight = document.querySelector(".news-card__img")
          ? document.querySelector(".news-card__img").clientHeight
          : 0;
        const arrows = document.querySelectorAll(".news-section-button");
        if (arrows.length > 0) {
          arrows.forEach((arrow) => {
            arrow.style.top = `${imageCardHeight / 2}px`;
            arrow.style.opacity = 1;
          });
        }
      }, 500);
    }
  }, [items]);
  return (
    <section className="sec-padding news-section">
      <div className="container">
        <div className="m-box">
          <h1 className="m-title">{title ? title : t("Новости")}</h1>
          {hasAllBtn && items.length > 4 && (
            <Link to={"/news"} className="m-link">
              {t("Все новости")}
            </Link>
          )}
        </div>
        {items.length > 0 && (
          <div className="news-section__wrapper">
            <Swiper
              loop={true}
              spaceBetween={15}
              slidesPerView={1}
              breakpoints={{
                1400: {
                  slidesPerView: 4,
                },
                992: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
              }}
              speed={1000}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Navigation]}
              className="news-section__slider"
              navigation={{
                prevEl: ".news-section-prev",
                nextEl: ".news-section-next",
              }}
            >
              {items.map((item, idx) => (
                <SwiperSlide key={item.id || item.slug || idx}>
                  <Link to={`/news/${item.slug}`} className="news-card">
                    <div className="news-card__img">
                      <img
                        src={get(item, "file.thumbnails.low.src")}
                        alt={get(item, "title")}
                      />
                    </div>
                    <div className="news-card__date">
                      {helpers.formatDate(item.published_at)}
                    </div>
                    <div className="news-card__title">{get(item, "title")}</div>
                    <div className="news-card__subtitle">
                      {get(item, "description")}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            {items.length > 4 && (
              <>
                <div className="news-section-button news-section-prev">
                  <svg
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.292893 7.29289C-0.097631 7.68342 -0.097631 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM2 7H1.5V9H2L2 7ZM1.5 7H1L1 9H1.5V7Z"
                      fill="#DD0802"
                    />
                  </svg>
                </div>

                <div className="news-section-button news-section-next">
                  <svg
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711L2.34315 15.0711C1.95262 15.4616 1.31946 15.4616 0.928932 15.0711C0.538408 14.6805 0.538408 14.0474 0.928932 13.6569L6.58579 8L0.928932 2.34315C0.538408 1.95262 0.538408 1.31946 0.928932 0.928932C1.31946 0.538408 1.95262 0.538408 2.34315 0.928932L8.70711 7.29289ZM7 7H7.5V9H7V7ZM7.5 7H8V9H7.5V7Z"
                      fill="#DD0802"
                    />
                  </svg>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default NewsSection;
